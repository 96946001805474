import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { socket } from "../constant/Socket";
import $ from "jquery";
function Commentry() {
  const { id,theam } = useParams();
  
  // const [record, setRecord] = useState({});
  const [key, setKey] = useState([])
  const [commentry, setCommentry] = useState({});
  const [commentry1, setCommentry1] = useState({});
  const [loderstatus, setloderstatus] = useState(true)
  const [text, setText] = useState("")
  const [refresh] = useState(false);

  useEffect(()=>{
    if(theam=="default"){

      $("body").addClass("light");
    }
    else{
      $("body").addClass("dark");
  
    }
  },[theam])

  useEffect(() => {
    socket.on(`recieve-commentry-${id}`, (result) => {
      if (result.status) {
        // setRecord(JSON.parse(result.data[0].commentry));
        let arr = [];
        let json = JSON.parse(result.data[0].commentry);
        if (json) {
          for (let key in json) {
            arr.push(key);
          }
          if (arr.length > 0) {
            setKey(arr)
            setCommentry(json[arr[0]]);
          }
          if(arr.length>1){
            setCommentry1(json[arr[1]]);
          }
        }
    } else {
      setCommentry({});
    }
    });
  }, [socket]);

  useEffect(() => {
    const getMatchCommentry = async () => {
      socket.emit("get-match-commentry", id);
      socket.on(`recieve-commentry-${id}`, (result) => {
        setloderstatus(true)
        if (result.status) {
            // setRecord(JSON.parse(result.data[0].commentry));
            
            let arr = [];
            let json = JSON.parse(result.data[0].commentry);
            if (json) {
              for (let key in json) {
                arr.push(key);
              }
              if (arr.length > 0) {
                setCommentry(json[arr[0]]);
              }
              setloderstatus(false)
              setText("")
            }
        } else {
          setCommentry({});
          setTimeout(() => {
            
            setloderstatus(false)
               setText("NO record found")
          }, 2000);
        }
      });
    };

    if (id) {
      getMatchCommentry();
    }
  }, [id, refresh]);

  return (
    <div className="container">
      {
        loderstatus?  <div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>:text?<div className=""><p className="no-record">{text}</p></div>:""
      }
    
    {Object.entries(commentry).map(([over, commentaryArray]) => (
      <div key={over}>
        {/* <h2>{over}</h2> */}
        {commentaryArray.map((commentary, index) => (
            <div className="man_dav" key={index}>
             
             
              {commentary.data.over? (
                <div className="box-class">
                 <div className="d-flex">
                 <span className="w500">End of {commentary.data.over} overs : {commentary.data.team_score} - {commentary.data.team_wicket}
                 </span>
                 </div>
                 <hr/>
                <div className="d-flex ">
                  <div className="boxes1 box-color">
                  <div className="">
                    <p>
                      {/* <strong>{commentary.data.over}</strong> */}
                      <strong>{commentary.data.team_score} - {commentary.data.team_wicket}</strong>
                    </p>
                    <p>{commentary.data.team}</p>
                  </div>
                  </div>
                  <div className="boxes box-color">
                  <div className="d-flex">
                  <span>{commentary.data.batsman_1_name}</span>
                      <span className="ml-auto">
                        {commentary.data.batsman_1_runs}(
                        {commentary.data.batsman_1_balls})
                      </span>
                
                    </div>

                    <div className="d-flex">
                    <span>{commentary.data.batsman_2_name}</span>
                      <span className="ml-auto">
                        {commentary.data.batsman_2_runs}(
                        {commentary.data.batsman_2_balls})
                      </span>
                    </div>

                  <div className="d-flex">
                  <span>{commentary.data.bolwer_name}</span>
                      <span className="ml-auto">
                        {commentary.data.bolwer_overs}-
                        {commentary.data.bolwer_maidens}-
                        {commentary.data.bolwer_runs}-
                        {commentary.data.bolwer_wickets}
                      </span>
                    </div>

                   


                    {/* <div className="d-flex">
                  <span>Runs Scored :</span>
                  < span className="ml-auto">{commentary.data.runs}</span>
                    </div>
                   */}
                  </div>
                
               
                 
                </div>
                </div>
              ) : (
                <div key={index} className="commentry-span">
                <span>
                  <strong>{commentary.data.overs}</strong>
                </span>
                <span className={`round_box1 ${commentary.data.runs>=1?"active":commentary.data.wicket>=1?"active1":""}`}>
                  <strong>{commentary.data.wicket>=1?'w':commentary.data.runs}</strong>
                </span>
                <div>
                  <p>{commentary.data.description?commentary.data.description:commentary.data.title}</p>
                </div>
              </div>
              )}
            </div>
          ))}
      </div>
    ))}
    {key.length>1?<div className="inning">Inning Break</div>:""}
    {Object.entries(commentry1).map(([over, commentaryArray]) => (
      <div key={over}>
        {/* <h2>{over}</h2> */}
        {commentaryArray.map((commentary, index) => (
            <div key={index}>
             
             
              {commentary.data.over? (
            <div className="box-class">
            <div className="d-flex">
            <span className="w500">End of {commentary.data.over} overs : {commentary.data.team_score} - {commentary.data.team_wicket}
            </span>
            </div>
            <hr/>
           <div className="d-flex ">
             <div className="boxes1 box-color">
             <div className="">
               <p>
                 <strong>{commentary.data.team_score} - {commentary.data.team_wicket}</strong>
               </p>
               <p>{commentary.data.team}</p>
             </div>
             </div>
             <div className="boxes box-color">
             <div className="d-flex">
             <span>{commentary.data.batsman_1_name}</span>
                 <span className="ml-auto">
                   {commentary.data.batsman_1_runs}(
                   {commentary.data.batsman_1_balls})
                 </span>
           
               </div>

               <div className="d-flex">
               <span>{commentary.data.batsman_2_name}</span>
                 <span className="ml-auto">
                   {commentary.data.batsman_2_runs}(
                   {commentary.data.batsman_2_balls})
                 </span>
               </div>

             <div className="d-flex">
             <span>{commentary.data.bolwer_name}</span>
                 <span className="ml-auto">
                   {commentary.data.bolwer_overs}-
                   {commentary.data.bolwer_maidens}-
                   {commentary.data.bolwer_runs}-
                   {commentary.data.bolwer_wickets}
                 </span>
               </div>

              


               {/* <div className="d-flex">
             <span>Runs Scored :</span>
             < span className="ml-auto">{commentary.data.runs}</span>
               </div> */}
             
             </div>
           
          
            
           </div>
           </div>
              ) : (
                <div key={index} className="commentry-span">
                <span >
                  <strong>{commentary.data.overs}</strong>
                </span>
                <span className={`round_box1 ${commentary.data.runs>=1?"active":""}`}>
                  <strong>{commentary.data.runs}</strong>
                </span>
                <div>
                  <p>{commentary.data.description?commentary.data.description:commentary.data.title}</p>
                </div>
              </div>
              )}
            </div>
          ))}
      </div>
    ))}
  </div>
  )

}

export default Commentry;
