import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Commentry from "./components/pages/Commentry";

function App() {
  return (
    <Router>
    <Routes>  
      <Route path="/:id/:theam" element={<Commentry />} />
    </Routes>
  </Router>
  );
}

export default App;
